import React from 'react';
import './footer.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'


const Footer = () => {
    const { t } = useTranslation()

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // smooth scrolling behavior
        });
    };
    return (
        <>
            <div className="wrapper-footer">
                <section className="main-footer">
                    <img src="\assets\shadows\footer-bg-shadow.svg" alt="img" className='img-fluid footer-bg-shadow' />
                    <img src="\assets\shadows\footer-bg-mobile.svg" alt="img" className='img-fluid footer-bg-mobile d-none' />
                    <div className="custom-container">
                        <div className="parent">
                            <div className="main-logo">
                                <a href="#"><img src="\assets\landing\gems-log.svg" alt="img" className='img-fluid' /></a>
                            </div>
                            <div className="right-navs">
                            <div className="main-nav">
                                    <h6>PRODUCTS</h6>
                                    {/* <Link to="/education">{t('Education')}</Link> */}
                                    <a onClick={scrollToTop}>Launchpad</a>
                                    <a href="\whitepaper.pdf" target="_blank" style={{pointerEvents: "none"}}>exchange <span className='comingsoon'>COMING SOON</span></a>
                                    <Link to="/" style={{pointerEvents: "none"}} >Academy <span className='comingsoon'>COMING SOON</span></Link>
                                </div>
                                <div className="main-nav">
                                    <h6>{t('Footer-link-heading')}</h6>
                                    <Link to="/education">{t('Education')}</Link>
                                    <Link to="/blog">Blog</Link>
                                    <a href="\whitepaper.pdf" target="_blank">{t('Gems-detailt45')}</a>
                                    <a
                                        href="https://staking.gems.vip/"
                                        target="_blank"
                                    >
                                        {t("GEMS-Stake")}
                                    </a>
                                    <Link to="/faqs" >{t('Faqs')}</Link>
                                </div>
                                <div className="main-nav">
                                    <h6>{t('Footer-link-heading1')}</h6>
                                    <Link to="/signupforproject" >{t('Where-btn1')}</Link>
                                    <a href="https://leaders.gems.vip/" target='_blank'>{t('Signinasaleqader')}</a>
                                    {/* <Link to="/apply" >{t('Applyfor')} {t('Countryman')}</Link> */}
                                    <Link to="/careers" >{t('Career')}</Link>

                                </div>
                                <div className="main-nav">
                                    {/* <h6>{t('Join')}</h6> */}
                                    <div className="social-links">
                                        <a href="https://twitter.com/Gems_VIP_" target="_blank" className='m-0'><img src="\assets\social-icons\twitter-n.svg" alt="img" className="img-fluid" /></a>
                                        <a href="http://t.me/GemsVIPOfficial" target="_blank" className='m-0'><img src="\assets\social-icons\telegram.svg" alt="img" className="img-fluid" /></a>
                                        <a href="https://www.linkedin.com/company/gemsvip/" target="_blank" className='m-0'><img src="\assets\social-icons\linkedin-n.svg" alt="img" className="img-fluid" /></a>
                                        <a href="https://coinmarketcap.com/currencies/gems-vip/" target="_blank" className='m-0'><img src="\assets\social-icons\cmc.svg" alt="img" className="img-fluid" /></a>
                                        <a href="https://www.coingecko.com/en/coins/gems-vip" target="_blank" className='m-0'><img src="\assets\social-icons\coingecko.svg" alt="img" className="img-fluid" /></a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="second-footer">
                    <div className="custom-container">
                        <div className="parent">
                            <h6>{t('Rights-reserved')}</h6>
                            <div className="twice-items">
                                <div className="inside-links">
                                    <Link to="/terms">Terms of use</Link>
                                    <Link to="/privacy">Privacy Policy</Link>
                                    <Link to="/amlpolicy">AML Policy</Link>
                                </div>
                                <a className='d-noneonmobile' onClick={scrollToTop}>{t('Gototop')} <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                    <g clip-path="url(#clip0_194_1206)">
                                        <path d="M2.02954 7.13691L3.47419 7.13691L3.47419 5.69224L2.02954 5.69224L2.02954 7.13691ZM3.47419 5.69224L4.91815 5.69224L4.91815 4.24829L3.47419 4.24829L3.47419 5.69224ZM4.91815 4.24829L6.3628 4.24829L6.36281 13.1685L7.80466 13.1685L7.80466 4.24829L9.2486 4.24829L9.2486 2.80362L7.80466 2.80362L7.80466 1.00009L6.3628 1.00009L6.3628 2.80362L4.91815 2.80362L4.91815 4.24829ZM9.2486 4.24829L9.2486 5.69224L10.6933 5.69224L10.6933 4.24829L9.2486 4.24829ZM10.6933 5.69224L10.6933 7.13691L12.1372 7.13691L12.1372 5.69224L10.6933 5.69224Z" fill="#9F9F9F" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_194_1206">
                                            <rect width="14" height="14" fill="white" transform="translate(0 14) rotate(-90)" />
                                        </clipPath>
                                    </defs>
                                </svg></a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </>
    )
}

export default Footer;
